const pricesData = [
  {
    title: "Строительный контроль (технадзор) за строительством и ремонтом",
    data: [
      {
        value:
          "Первый и любой последующий выезд с составлением экспресс-отчета в пределах МКАД и до ЦКАД*",
        price: "12 000",
      },
      {
        value:
          "Первый и любой последующий выезд с составлением экспресс-отчета в районе ЦАО г. Москвы",
        price: "15 000",
      },
      {
        value:
          "Первый и любой последующий выезд с составлением экспресс-отчета за  ЦКАД*",
        price: "14 000",
      },
      {
        value:
          "Выезд в города Жуковский, Раменское, Бронницы, Гжель, Клин, Наро-Фоминск, Ступино",
        price: "15 000",
      },
      {
        value:
          "Верея, Можайск, Руза, Волокаламск, Дубна",
        price: "20 000",
      },
    ],
  },
  {
    title: "Проверка объекта недвижимости перед покупкой",
    data: [
      { value: "Проверка дома перед покупкой", price: "от 20 000" },
      { value: "Проверка квартиры перед покупкой", price: "от 20 000" },
      {
        value: "Тепловизионное обследование",
        price: "от 12 000",
      },
      {
        value: "Обследование инженерных систем",
        price: "от 12 000",
      },
      {
        value: "Обследование плоских и скатных кровель",
        price: "от 12 000",
      },
      {
        value: "Судебная/досудебная строительно-техническая экспертиза",
        price: "от 55 000",
      },
      { value: "Полный рабочий день на объекте", price: "40 000" },
      {
        value:
          "Проверка рабочей документации на предмет ошибок или оптимизации.",
        price: "3 000/час",
      },
      {
        value: "Поверочные расчеты/ расчеты конструктивных элементов",
        price: "от 10 000",
      },
    ],
  },
  {
    title: "Проектирование инженерных систем",
    data: [
      {
        value: "Проектирование инженерных систем – Электрика (ЭОМ)",
        price: "200 руб./м2",
      },
      {
        value:
          "Проектирование инженерных систем – Отопление, водоснабжение, канализирование (ОВК)",
        price: "250 руб./м2",
      },
    ],
  },
];

export default pricesData;