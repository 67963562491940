import styles from "./Footer.module.scss";
import Logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import Button from "../Button";
import { FaTelegram, FaWhatsapp } from "react-icons/fa";
import telegramLogo from "../../assets/images/telegram.svg";
import whatsappLogo from "../../assets/images/whatsapp.svg";
import FeedbackForm from "@components/FeedbackForm";
import Modal from "react-modal";
import { useState } from "react";
import dzenLogo from "@images/zen_logo_black.svg";
import youtubeLogo from "@images/youtube_logo.svg";

export default function Footer() {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <footer className={styles.footer}>
      <Link to={"/"} className={styles.logo}>
        <img src={Logo} className={styles.logoIcon} alt="Кирпичный дом" />
      </Link>
      <div className={styles.contacts}>
        <address className={styles.address}>
          <a href="tel:+79152455955" className={styles.contactLink}>
            <AiOutlinePhone className={styles.icon} />
            <div className={styles.text}>+7 (915) 245-59-55</div>
          </a>

          <a href="mailto:info@teh-nadzor.pro" className={styles.contactLink}>
            <AiOutlineMail className={styles.icon} />
            <div className={styles.text}>info@teh-nadzor.pro</div>
          </a>
        </address>
        <div className={styles.socials}>
          <Button
            theme="accent"
            className={styles.orderBtn}
            handler={() => {
              setModalOpen(true);
            }}
          >
            Заказать услугу
          </Button>
          <a href="https://t.me/brickhomeru" className={styles.contactLink}>
            {/*<FaTelegram className={styles.icon} />*/}
            <img
              src={telegramLogo}
              className={`${styles.icon} ${styles.telegram}`}
            />
          </a>
          <a href="https://wa.me/79263517218" className={styles.contactLink}>
            {/*<FaWhatsapp className={styles.icon} />*/}
            <img
              src={whatsappLogo}
              className={`${styles.icon} ${styles.whatsapp}`}
            />
          </a>
          <a
              href="https://dzen.ru/id/5ee52a8d790ceb6048923b53"
              target="_blank"
              className={`${styles.contactLink} ${styles.dzen}`}
          >
            <img
                src={dzenLogo}
                className={`${styles.icon} ${styles.dzen}`}
            />
          </a>
          <a
              href="https://youtube.com/@brickhome_stroycontrol"
              target="_blank"
              className={`${styles.contactLink} ${styles.youtube}`}
          >
            <img
                src={youtubeLogo}
                className={`${styles.icon} ${styles.youtube}`}
            />
          </a>
        </div>
      </div>

      <div className={styles.copyright}>
        {/*<p>Copyright © 2020 - {new Date().getFullYear()}</p>*/}
        <p>ИП Меграбян Г.Ю. ОГРНИП 323508100615246</p>
        <Link to={"privacy-policy"}>Политика конфиденциальности</Link>
      </div>
      <Modal
        isOpen={modalOpen}
        className={styles.modalRoot}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalContent}>
          <button
            type="button"
            className={styles.modalCloseBtn}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            &#x274C;
          </button>
          <FeedbackForm />
        </div>
      </Modal>
    </footer>
  );
}
