import styles from "./Contacts.module.scss";
import telegramLogo from "@images/telegram.svg";
import whatsappLogo from "@images/whatsapp.svg";

export default function Contacts() {
  return (
    <div className={styles.root}>
      <div className={styles.contentWrap}>
        <h1 className={styles.h1}>Контактная информация</h1>

        <div className={styles.text}>
          Основной регион работы г. Москва и Московская область.
        </div>
        <div className={styles.contacts}>
          <address className={styles.address}>
            <a href="tel:+79152455955" rel="nofollow" className={styles.contactLink}>
              <div className={styles.text}>Телефон: +7 (915) 245-59-55</div>
            </a>

            <a href="mailto:info@teh-nadzor.pro" rel="nofollow" className={styles.contactLink}>
              <div className={styles.text}>E-mail: info@teh-nadzor.pro</div>
            </a>
          </address>
          <div className={styles.socials}>
            <a href="https://t.me/brickhomeru" rel="nofollow" className={styles.contactLink}>
              <div className={styles.text}>Telegram:</div>
              <img
                src={telegramLogo}
                className={`${styles.icon} ${styles.telegram}`}
              />
            </a>
            <a href="https://wa.me/79263517218" rel="nofollow" className={styles.contactLink}>
              <div className={styles.text}>Whatsapp:</div>
              <img
                src={whatsappLogo}
                className={`${styles.icon} ${styles.whatsapp}`}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
